import React, { useState } from 'react';

const LightBox = ({ children, src, alt, Wrapper = 'div', zIndex = 100 }) => {
	const [isOpen, setIsOpen] = useState(false);

	const toggleIsOpen = () => {
		setIsOpen(!isOpen);
	};

	return (
		<Wrapper onClick={toggleIsOpen}>
			{isOpen ?
				<div onClick={toggleIsOpen} style={{
					position: 'fixed',
					top: '0',
					left: '0',
					height: '100vh',
					width: '100vw',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
					backgroundColor: 'rgba(0,0,0,0.7)',
					cursor: 'pointer',
					zIndex
				}}>
					<img src={src}
						alt={alt}
						style={{
							height: 'auto',
							width: '100%',
                            maxWidth: "900px"
						}}
					/>
				</div>
				: null}
			
            {children}
            
		</Wrapper>
	);
};

export default LightBox;