import * as React from "react";

const Footer = ({ children }) => {
  return (
    <footer>
        <span>Wszystkie prawa zastrzeżone www.rivieraresidence.pl</span>
    </footer>
  )
}

export default Footer;
