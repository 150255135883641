import React from "react";

import arrowLeft from "../images/arrowLeft.svg";
import arrowRight from "../images/arrowRight.svg";

const SectionHeader = ({children}) => {
    return (

        <div className="section-header" data-aos="fade-up">
            <img src={arrowLeft} alt="" width="163" height="4"/>
            <h3>{children}</h3>
            <img src={arrowRight} alt="" width="163" height="4"/>
        </div>

    )
}

export default SectionHeader;



