// import { StaticImage } from "gatsby-plugin-image"
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import SectionHeader from "./sectionHeader";

const Amenities = () => {
    return (
        <section className="amenities">
            <SectionHeader>
                Udogodnienia
            </SectionHeader>
            <ul className="amenities__list">
                <li className="amenities__list--item" data-aos="fade-up">
                    <StaticImage 
                        src="../images/basen.png" alt="Basen" 
                        placeholder="tracedSVG"
                    />
                    <p>Basen</p>
                </li>
                <li className="amenities__list--item" data-aos="fade-up" data-aos-delay="100">
                    <StaticImage 
                        src="../images/sauna.png" alt="Sauna" 
                        placeholder="tracedSVG"
                    />
                    <p>Sauna</p>
                </li>
                <li className="amenities__list--item" data-aos="fade-up" data-aos-delay="200">
                    <StaticImage 
                        src="../images/lokalizacja.png" alt="Tarasy widokowe" 
                        placeholder="tracedSVG"
                    />
                    <p>Tarasy<br />widokowe</p>
                </li>
                <li className="amenities__list--item" data-aos="fade-up" data-aos-delay="300">
                    <StaticImage 
                        src="../images/widoki.png" alt="Góry" 
                        placeholder="tracedSVG"
                    />
                    <p>Góry</p>
                </li>
                <li className="amenities__list--item" data-aos="fade-up" data-aos-delay="400">
                    <StaticImage 
                        src="../images/ściezki-rowerowe.png" alt="Ścieżki Rowerowe" 
                        placeholder="tracedSVG"
                    />
                    <p>Ścieżki<br />rowerowe</p>
                </li>
                <li className="amenities__list--item" data-aos="fade-up" data-aos-delay="500">
                    <StaticImage 
                        src="../images/korty-tenisowe.png" alt="Korty Tenisowe" 
                        placeholder="tracedSVG"
                    />
                    <p>Korty<br />tenisowe</p>
                </li>
                <li className="amenities__list--item" data-aos="fade-up" data-aos-delay="600">
                    <StaticImage 
                        src="../images/wyciąg.png" alt="Stoki Narciarskie" 
                        placeholder="tracedSVG"
                    />
                    <p>Stoki<br />Narciarskie</p>
                </li>
                <li className="amenities__list--item" data-aos="fade-up" data-aos-delay="700">
                    <StaticImage 
                        src="../images/scieżki.png" alt="Rzeka Wisła" 
                        placeholder="tracedSVG"
                    />
                    <p>Rzeka<br />Wisła</p>
                </li>

            </ul>
        </section> 
    )
}

export default Amenities;



