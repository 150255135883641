import { StaticImage } from "gatsby-plugin-image"
import React from "react"

import plusIcon from "../images/plus-icon.svg"
import LightBox from "./lightbox"

import img1 from "../images/mieszkanie_parter.png"
import img2 from "../images/mieszkanie_2_pietro.png"
import img3 from "../images/mieszkanie_1_pietro.png"

const Gallery = () => {
  return (
    <section className="gallery">
      <ul className="gallery__list">
        <li className="gallery__item">
          <strong data-aos="fade-up">Parter</strong>
          <LightBox src={img1}>
            <div
              className="gallery__item--wrapper"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <div className="gallery__item--zoom">
                <img src={plusIcon} alt="Powiększ" />
              </div>
              <StaticImage
                src="../images/mieszkanie_parter.png"
                alt="Riviera Residence - parter"
                className="gallery__item--image"
                objectPosition="50% 50%"
              />
            </div>
          </LightBox>
        </li>
        <li className="gallery__item">
          <strong data-aos="fade-up">1 Piętro</strong>
          <LightBox src={img3}>
            <div
              className="gallery__item--wrapper"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <div className="gallery__item--zoom">
                <img src={plusIcon} alt="" />
              </div>
              <StaticImage
                src="../images/mieszkanie_1_pietro.png"
                alt="Riviera Residence - parter"
                className="gallery__item--image"
                objectPosition="50% 50%"
              />
            </div>
          </LightBox>
        </li>
        <li className="gallery__item">
          <strong data-aos="fade-up">2 Piętro</strong>
          <LightBox src={img2}>
            <div
              className="gallery__item--wrapper"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <div className="gallery__item--zoom">
                <img src={plusIcon} alt="" />
              </div>
              <StaticImage
                src="../images/mieszkanie_2_pietro.png"
                alt="Riviera Residence - parter"
                className="gallery__item--image"
                objectPosition="50% 50%"
              />
            </div>
          </LightBox>
        </li>
      </ul>

      <strong data-aos="fade-up">
        Istnieje możliwość połączenia apartamentów w przypadku zakupu więcej niż
        jednego
      </strong>
    </section>
  )
}

export default Gallery
