import * as React from "react"
import logo from "../images/Riviera_Sygnet.svg"

const Divider = ({ children, text }) => {
  return (
    <div className="divider">
      <div className="divider__content">
        <img
          className="divider__content--logo animate"
          data-aos="fade-up"
          src={logo}
          alt="Riviera Residence"
          width="130"
          height="130"
        />
        <span
          className="divider__content--line line-animate"
          data-aos="line-ani"
          data-aos-delay="200"
        ></span>
        <strong
          className="divider__content--text animate"
          data-aos="fade-up"
          data-aos-delay="400"
        >
          {text}
        </strong>
      </div>
      <figure className="divider__bg">{children}</figure>
    </div>
  )
}

export default Divider
