import React, { useEffect } from "react"
import { StaticImage } from "gatsby-plugin-image"

import "../scss/main.scss"

import AOS from "aos"
import "aos/dist/aos.css"

import Layout from "../components/layout"
import Seo from "../components/seo"

import Divider from "../components/divider"
import About from "../components/about"
import Gallery from "../components/gallery"
import Amenities from "../components/amenities"
import Contact from "../components/contact"

const IndexPage = () => {
  useEffect(() => {
    AOS.init({
      once: true,
      duration: 1200,
      easing: "ease-out-cubic",
      disable: "mobile",
      animatedClassName: "animated",
    })
  }, [])

  return (
    <Layout>
      <Divider text="Riviera Residence to inwestycja inna niż wszystkie">
        <StaticImage
          src="../images/Vector.jpg"
          placeholder="blurred"
          alt=""
          style={{ position: "absolute" }}
          className="bg--image"
        />
      </Divider>

      <About />

      <Gallery />

      <Divider
        text={[
          "Chcesz dowiedziec się więcej?",
          <p> Zadzwoń albo napisz sms a my oddzwonimy </p>,
          <a href="tel:+48504359991">+48 504 359 991</a>,
        ]}
      >
        <StaticImage
          src="../images/Vector_2.jpg"
          placeholder="blurred"
          alt=""
          style={{ position: "absolute" }}
          className="bg--image"
        />
      </Divider>

      <Amenities />
      <Contact />
    </Layout>
  )
}

export const Head = () => <Seo title="Riviera Residence" />

export default IndexPage
