import React, { useRef, useState } from "react"
import ReactPlayer from "react-player"

import logo from "../images/RivieraLogo-cropped.svg"

import instagramIcon from "../images/instagram-icon.svg"
import facebookIcon from "../images/facebook-icon.svg"
import { StaticImage } from "gatsby-plugin-image"

const Header = () => {
  const [played, setPlayed] = useState(false)
  const myRef = useRef(null)
  const executeScroll = () => myRef.current.scrollIntoView()

  return (
    <>
      <header className="header">
        <ul className="header__socials">
          <li>
            <a href="https://facebook.com" target="_blank" rel="noreferrer">
              <img
                src={instagramIcon}
                alt="Riviera Instagram"
                width="24"
                height="24"
              />
            </a>
          </li>
          <li>
            <a href="https://facebook.com" target="_blank" rel="noreferrer">
              <img
                src={facebookIcon}
                alt="Riviera Facebook"
                width="16"
                height="24"
              />
            </a>
          </li>
        </ul>

        <h1 className="header__logo">
          <img src={logo} alt="Riviera Residence" width={286} height={160} />
        </h1>

        <nav className="header__nav">
          <ul>
            <li>
              <a href="#o-inwestycji" className="header__nav--link">
                O inwestycji
              </a>
            </li>
            <li>
              <a href="#lokalizacja" className="header__nav--link">
                Lokalizacja
              </a>
            </li>
            <li>
              <a href="#kontakt" className="header__nav--link">
                Kontakt
              </a>
            </li>
          </ul>
          <ul className="header__mobile-socials">
            <li>
              <a href="https://facebook.com" target="_blank" rel="noreferrer">
                <img
                  src={instagramIcon}
                  alt="Riviera Instagram"
                  width="24"
                  height="24"
                />
              </a>
            </li>
            <li>
              <a href="https://facebook.com" target="_blank" rel="noreferrer">
                <img
                  src={facebookIcon}
                  alt="Riviera Facebook"
                  width="16"
                  height="24"
                />
              </a>
            </li>
          </ul>
        </nav>
      </header>

      <section className="entry">
        <div className={`entry--text ${played ? "hidden" : ""}`}>
          <strong data-aos="fade-up" data-aos-delay="0">
            Wyjątkowe Miejsce
          </strong>
          <h2 data-aos="fade-up" data-aos-delay="200">
            12 luksusowych apartamentów w Ustroniu już w sprzedaży!
          </h2>
        </div>
        <figure className={`entry--arrow ${played ? "hidden" : ""}`}>
          <button onClick={executeScroll} className="bounce">
            <svg
              width="14"
              height="78"
              viewBox="0 0 14 78"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.5 62V1M7.00944 77C8.63271 77 13.8217 65.359 12.8886 64.0384C11.9555 62.7177 2.15258 62.5915 1.13028 64.0384C0.107989 65.4871 5.38747 77 7.00944 77Z"
                stroke="#AD8E51"
                strokeWidth="1.98948"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        </figure>
        <figure className="entry__video">
          {played ? (
            <div className="player-wrapper">
              <ReactPlayer
                className="react-player"
                url="https://vimeo.com/753999985"
                light={true}
                width="100%"
                height="100%"
              />
            </div>
          ) : (
            <div className="entry__video--placeholder">
              <StaticImage
                src="../images/video_placeholder.jpeg"
                // layout="fullWidth"
                objectFit="cover"
                layout="fullWidth"
                alt=""
                class="placeholder"
              />
              <div className="play-btn" onClick={() => setPlayed(true)}>
                <div className="play-btn-wrapper">
                  <svg
                    width="79"
                    height="91"
                    viewBox="0 0 79 91"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M79 45.5L0.249996 90.9663L0.25 0.0336613L79 45.5Z"
                      fill="#797979"
                    />
                  </svg>
                </div>
              </div>
            </div>
          )}
        </figure>
      </section>

      <section className="description" ref={myRef}>
        <ul className="description__list">
          <li className="description__list--item" data-aos="fade-up">
            <strong>Doskonała lokalizacja</strong>
            <p>
              Nad samymi bulwarami rzeki Wisły, przy ścieżkach rowerowych,
              kortach tenisowych, trasach trekkingowych, a także blisko ścisłego
              centrum.
            </p>
          </li>
          <li
            className="description__list--item"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            <strong>Tarasy widokowe</strong>
            <p>
              Przestronne tarasy z widokiem na najpiękniejsze szczyty Beskidu
              Śląskiego (legendarną Czantorię i Równicę), prywatny basen, saunę
              oraz parking.
            </p>
          </li>
          <li
            className="description__list--item"
            data-aos="fade-up"
            data-aos-delay="400"
          >
            <strong>Wysoki standard</strong>
            <p>
              W Riviera Residence jesteśu siebie. W 50 lub 90 metrowym
              apartamencie masz pełnię luksusu. Jedna z najbardziej prestiżowych
              lokalizacji uzdrowiska Ustroń.
            </p>
          </li>
        </ul>
      </section>
    </>
  )
}

export default Header
