import React from "react"
import SectionHeader from "./sectionHeader"
import { useForm, ValidationError } from "@formspree/react"

import contactIcon from "../images/contact.svg"

function ContactForm() {
  const [state, handleSubmit] = useForm("xoqbkvpj")
  if (state.succeeded) {
    return (
      <p>Dziękujemy za kontakt. odpowiemy najszybciej jak to będzie możliwe.</p>
    )
  }
  return (
    <form onSubmit={handleSubmit} className="contact__form">
      <div>
        <label htmlFor="email">Email</label>
        <input id="email" type="email" name="email" required={true} />
        <ValidationError prefix="Email" field="email" errors={state.errors} />
      </div>
      <div>
        <label htmlFor="message">Wiadomość</label>
        <textarea id="message" name="message" rows={7} required={true} />
        <ValidationError
          prefix="Message"
          field="message"
          errors={state.errors}
        />
      </div>
      <button type="submit" disabled={state.submitting}>
        Wyślij
      </button>
    </form>
  )
}

const Contact = () => {
  return (
    <>
      <section className="location" id="lokalizacja">
        <SectionHeader>Lokalizacja</SectionHeader>
        <address data-aos="fade-up" data-aos-delay="200">
          ul. Armii Krajowej 2, Ustroń
        </address>

        <div className="map__wrapper" data-aos="fade-up">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2580.093464806871!2d18.824043!3d49.7090421!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47141bf05103faf9%3A0x896618569e426ed2!2sArmii%20Krajowej%202%2C%2043-450%20Ustro%C5%84!5e0!3m2!1spl!2spl!4v1664142827172!5m2!1spl!2spl"
            width="1920"
            allowFullScreen=""
            title="Mapa Lokalizacji"
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </section>

      <section className="contact" id="kontakt">
        <SectionHeader>Kontakt</SectionHeader>
        <strong data-aos="fade-up">
          Chcesz dowiedzieć się więcej?
          <br />
          Zadzwoń albo napisz sms a my oddzwonimy
        </strong>
        <a href="tel:+48504359991" data-aos="fade-up" data-aos-delay="200">
          <img src={contactIcon} alt="Numer telefonu:" width="26" height="26" />
          <span>+48 504 359 991</span>
        </a>
        {/* 
        <a
          href="mailto:dawid.zyzanski@remax-polska.pl"
          data-aos="fade-up"
          data-aos-delay="200"
        >
          { <img src={emailIcon} alt="Numer telefonu:" width="26" height="26" /> }
          <StaticImage
            src="../images/envelope.png"
            alt="Email:"
            width={26}
            height={26}
          />
          <div>
            <span>Dawid Zyzański</span>
            <span>dawid.zyzanski@remax-polska.pl</span>
          </div>
        </a> */}
      </section>

      <section className="form">
        <SectionHeader>Formularz Kontaktowy</SectionHeader>
        <ContactForm />
      </section>
    </>
  )
}

export default Contact
