import { StaticImage } from "gatsby-plugin-image"
import * as React from "react"

import SectionHeader from "./sectionHeader"

const About = () => {
  return (
    <section className="about" id="o-inwestycji">
      <SectionHeader>O inwestycji</SectionHeader>

      <article>
        <ul>
          <li>
            <div data-aos="fade-up">
              <p>
                Zapraszamy do wyjątkowego miejsca, które zapewni Ci
                niezapomniane chwile w postaci pięknych górskich widoków,
                kontaktu z niespotykaną przyrodą oraz zagwarantuje możliwość
                odpoczynku od miejskiego zgiełku. Riviera Residence to
                inwestycja inna niż wszystkie.
              </p>
            </div>

            <figure className="article__imgContainer" data-aos="fade-left">
              <StaticImage
                src="../images/1.jpeg"
                alt="Riviera - O inwestycji"
                className="article--image"
                placeholder="tracedSVG"
                width={1000}
              />
            </figure>
          </li>
          <li>
            <figure className="article__imgContainer" data-aos="fade-right">
              <StaticImage
                src="../images/2.jpeg"
                alt="Riviera - O inwestycji"
                className="article--image"
                placeholder="tracedSVG"
                width={1000}
              />
            </figure>
            <div data-aos="fade-up">
              <p>
                W Riviera Residence jesteś u siebie. W 50 lub 90 metrowym
                apartamencie masz pełnię luksusu, przestronne tarasy z widokiem
                na najpiękniejsze szczyty Beskidu Śląskiego (legendarną
                Czantorię i Równicę), prywatny basen, saunę oraz parking.
                Nieruchomość zlokalizowana jest nad samymi bulwarami rzeki
                Wisły, przy ścieżkach rowerowych, kortach tenisowych, trasach
                trekkingowych,a jednocześnie skomunikowana drogą do niedalekiego
                ścisłego centrum, gdzie spacerem trafiasz w ciągu 5 minut mogąc
                delektować się lokalną kuchnią, kulturą, tradycją i w pełni
                czerpać z życia uzdrowiska.
              </p>
            </div>
          </li>
          <li>
            <div data-aos="fade-up">
              <p>
                Umiejscowiona w centrum, a jednocześnie w bliskim kontakcie z
                naturą i rzeką Wisła. Jedna z najbardziej prestiżowych
                lokalizacji uzdrowiska Ustroń, odwiedzanego przez setki tysięcy
                turystówi kuracjuszy. Zaprojektowana inaczej niż wszystkie.To
                nie jest kolejna inwestycja ze wspólną restauracją i
                dziesiątkami 25 metrowych apartamentów.
              </p>
            </div>
            <figure className="article__imgContainer" data-aos="fade-left">
              <StaticImage
                src="../images/3.jpeg"
                alt="Riviera - O inwestycji"
                className="article--image"
                placeholder="tracedSVG"
                width={1000}
              />
            </figure>
          </li>
        </ul>
      </article>
    </section>
  )
}

export default About
